/********** Template CSS ********* 9 #191C24 #6C7293*/
:root {
    --primary:#ffc107 ;
    --secondary:   #37B049;
    --light: #ffff;
    --dark: #000000;
    --grey:#adb5bd ;
    --white:#fff;

    /*size var*/
   --sidebar_size:280px;
   --topbar_size:100px;
}
*{
    font-family: 'Roboto', sans-serif;
    font-size:large ;
    
}

.back-to-top {
    position: fixed;
    display: none;
    right: 45px;
    bottom: 45px;
    z-index: 99;
}


/*** Layout ***/
.sidebar {
   position: fixed;
   top: 0px;
   left: 0px;
   bottom: 0px;
    width: var(--sidebar_size);
    margin-left: 0;
    padding-left: 0;
    padding-top: 0.5em;
    height: 100vh;
    overflow-y: auto;
    background: var(--secondary);
    transition: 0.5s;
    z-index: 999;
}
.topbar{
    background: var(--secondary);
    margin-left:var(--sidebar_size);
    height:var(--topbar_size) ;
}

.content {
    margin-left: var(--sidebar_size);
    margin-top: var(--topbar_size);
    min-height: 100vh;
    transition: 0.5s;
}

@media (min-width: 992px) {
    

 
}

@media (max-width: 991.98px) { 
  
}


/*** Navbar ***/
.sidebar .navbar .navbar-nav .nav-link {
    padding: 7px 20px;
    color: var(--light);
    font-weight: 500;
    border-left: 3px solid var(--secondary);
    border-radius: 0 30px 30px 0;
    outline: none;
}

.sidebar .navbar .navbar-nav .nav-link:hover,
.sidebar .navbar .navbar-nav .nav-link.active {
    color: var(--primary);
    background: var(--light);
    border-color: var(--primary);
}

.sidebar .navbar .navbar-nav .nav-link i {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: var(--grey);
    border-radius: 40px;
}

.sidebar .navbar .navbar-nav .nav-link:hover i,
.sidebar .navbar .navbar-nav .nav-link.active i {
    background: var(--secondary);
}
/* le innercontent c'est pour tous les compsant fils de home*/
.inner_content{
    margin-top: 10px;
    margin-left: 40px;
}

.logo {
    width: 100px; /* Définissez la largeur souhaitée */
    height: auto; /* Gardez la hauteur automatique pour conserver les proportions */
}


.content .navbar .navbar-nav .nav-link {
    margin-left: 20px;
    padding: 12px 0;
    color: var(--light);
    outline: none;
}

.content .navbar .navbar-nav .nav-link:hover,
.content .navbar .navbar-nav .nav-link.active {
    color: var(--primary);
}

.content .navbar .sidebar-toggler,
.content .navbar .navbar-nav .nav-link i {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: var(--dark);
    border-radius: 40px;
}


.content .navbar .dropdown-item:hover,
.content .navbar .dropdown-item.active {
    background: var(--dark);
}




@media (max-width: 575.98px) {
    
}

.icon{
    color: var(--primary);
    
}
 .name:hover{
    color: var(--primary);
    
}
.icon :hover{
    color: var(--primary);
}
.subitem :hover{
    color: var(--primary);
}

.submenu-item{
    font-size:14px;
    margin:0;
    padding:2px
}
.min-icon{
    width: 10px;
    height: 20px;
    margin-right: 2px;
}
/**/
.login-page{
    background: linear-gradient(rgba(0, 50, 0, 0.2), rgba(255, 255, 255, 1)), url(./assets/man.jpg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; 
}
 
.register-page{
    margin-top:calc( var(--topbar_size)+20) ;
    margin: 10px;
  height: 80vh;

}
.profil-bg{

    height: 100vh;

    background: rgba(8, 139, 108, 0.1);

}